import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import Breadcrumbs from '../../components/global/breadcrumbs';
import {
  FullWidthSection,
  Page,
  PageContent,
  PageSubTitle,
  PageTitle
} from '../../components/global/page';
import {
  Column,
  navigateTo,
  isWindow,
  Tag
} from '../../components/global/utils';
import { Button } from '../../components/global/button';
import Layout from '../../components/layout/layout';
import Seo from '../../components/seo';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import { WorkshopDetails } from '../../views/workshops/components/workshopBox';
import { AgendaBar, MobileAgendaBar } from './components/agenda';
import { Speaker } from './components/speakers';
import {
  Description,
  FullWidthTitle,
  Img,
  SignInButton,
  WhiteText
} from './styled.components';
import { SpeakerGrid } from './styled.components';

export default function WorkshopPage({ pageContext }) {
  const { t } = useTranslation();
  const breadcrumbsPath = [
    { name: t('workshop-page.breadcrumbs.workshops'), path: '/news/workshops' },
    { name: t('workshop-page.breadcrumbs.workshop'), path: '' }
  ];
  let Agenda = AgendaBar;
  const isMobile = isWindow() && window.innerWidth < 768;

  if (isMobile) Agenda = MobileAgendaBar;
  const isWorkshopActive = new Date() < new Date(pageContext.data);
  return (
    <Layout withHeader={false}>
      <Seo
        title={pageContext.title}
        description={pageContext.description.description}
        ogImage={pageContext.thumbnail.file.url}
      />

      <Page className="workshopPage">
        <PageContent>
          <Breadcrumbs path={breadcrumbsPath}></Breadcrumbs>
          <PageTitle>{pageContext.title}</PageTitle>
          <WorkshopDetails {...pageContext} />
          <Description>
            {pageContext.content
              ? renderRichText(pageContext.content)
              : pageContext.description.description}
          </Description>
          {isWorkshopActive && (
            <SignInButton
              className="solid"
              onClick={() => navigateTo(pageContext.link, '_blank')}>
              {t('workshop-page.sign-in')}
            </SignInButton>
          )}
          <Img src={pageContext.thumbnail?.file?.url}></Img>
          {pageContext.agenda && (
            <>
              <PageSubTitle>
                <span>Agenda</span> spotkania
              </PageSubTitle>
              <Column>
                {pageContext.agenda.map((agendaItem) => (
                  <Agenda
                    pageContext={pageContext}
                    key={agendaItem.panelName}
                    {...agendaItem}
                    {...{
                      speaker: pageContext.speakers.find(
                        (speaker) => speaker.id === agendaItem?.speaker?.id
                      ),
                      isMobile
                    }}
                  />
                ))}
              </Column>
            </>
          )}
          {pageContext.speakers && (
            <>
              <PageSubTitle style={{ marginTop: '7.5rem' }}>
                Kogo posłuchasz na <span>warsztatach</span>
              </PageSubTitle>
              <SpeakerGrid style={{ marginBottom: '6rem' }}>
                {pageContext.speakers.map((speaker) => (
                  <>
                    <Speaker key={speaker.name} {...speaker}></Speaker>
                  </>
                ))}
              </SpeakerGrid>
            </>
          )}
        </PageContent>
        {isWorkshopActive && (
          <FullWidthSection className="align-center">
            <FullWidthTitle>
              {t('workshop-page.full-width.title')}
            </FullWidthTitle>
            <WhiteText>{t('workshop-page.full-width.desc')}</WhiteText>
            <Button
              className="outlined"
              onClick={() => navigateTo(pageContext.link, '_blank')}>
              {t('workshop-page.full-width.button')}
            </Button>
          </FullWidthSection>
        )}
      </Page>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
